import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";
require("vue-i18n");

export function fetchChannel(channelId) {
  return axios.get(baseUrl + baseApiVersion + `/channel/${channelId}`, {
    headers,
  });
}

export function fetchChannelListCounts(params){
  return axios.get(baseUrl + baseApiVersion + `/channel/list/count`, {
    params,
    headers,
  });
}

export function fetchChannelList(params) {
  return axios.get(baseUrl + baseApiVersion + `/channel/list`, {
    headers,
    params,
  });
}

export function fetchChannelAssignList(params) {
  return axios.get(baseUrl + baseApiVersion + `/channel/assign/log/list`, {
    params,
    headers,
  });
}

export function fetchChannelCheckStream(channelId) {
  return axios.get(baseUrl + baseApiVersion + `/channel/${channelId}/check/stream`, {
    headers,
  });
}



export function getLiveUrl(channelData) {
  if(channelData == undefined ||  channelData.streamServer == null) {
    return "-"
  }
  var properties = channelData.streamServer.properties
  var url = properties.liveUrl+"/" + properties.application + "/" + channelData.streamKey +"/" + properties.livePostfix
  return url.replace('?DVR',"")
}

export function getChannelStatusName(channel){
  var result = "-";

  //에러
  if(channel.status == "Error") {
    result = "live-data-channel-status-error"
  }

  //대기
  if(channel.assignedStatus == "Unassigned") {
    result = "live-data-channel-status-ready"
  }

  //녹화
  if(channel.assignedStatus == "Assigned" && channel.recordStatus == "Record") {
    result = "live-data-channel-status-recoding"
  }

  //중계
  if(channel.assignedStatus == "Assigned") {
    result = "live-data-channel-status-live"
  }

  return result;
}

export function fetchChannelLiveUrlCheck(url){
  return axios.get(url, {
    headers,
  });
}


export function getDeviceTypeName(deviceType) {
  var result = ""
  switch (deviceType){
    case "Drone" :
      result = "channel-device-type-drone-name"
      break;
    case "Mobile" :
      result = "channel-device-type-mobile-name"
      break;
    case "Cam" :
      result = "channel-device-type-cam-name"
      break;
    case "Station" :
      result = "channel-device-type-station-name"
      break;
    case "Unknown" :
      result = "text-unknown"
      break;
    case "Unassigned" :
      result = "text-unassigned"
      break;
    default:
      result = "-"
  }
  return result;
}
