class MapMarkerInfoData {
    constructor () {
        this.mapDataType = null
        this.selectId = null
        this.selectType = null
        this.name = null
        this.userName = null
        this.userDepartmentName = null

        this.type = null
        this.typeName = null
        this.tag = null
        this.registerDate = null
        this.registerUserName = null
        this.lng = null
        this.lat = null


        this.pointType = null
        this.memo = null
        this.isOwner = null
        this.editMode = false
    }

    setPointInfoData(info){
        this.selectId = info.selectId;
        this.selectType = info.selectType;
        this.lng = info.lng;
        this.lat = info.lat;
        this.memo = info.memo;
        this.isOwner = info.isOwner;
        this.editMode = info.editMode;
        this.userDepartmentName = info.registerUserDepartmentName;
        this.userName = info.registerUserName;
    }

    setDroneInfoData(info){
        this.selectId = info.selectId;
        this.selectType = info.selectType;
        this.lng = info.lng;
        this.lat = info.lat;
        this.name = info.deviceName;
        this.userDepartmentName = info.userDepartmentName;
        this.userName = info.userName;
    }

    setUserInfoData(info){
        this.selectId = info.selectId;
        this.selectType = info.selectType;
        this.lng = info.lng;
        this.lat = info.lat;
        this.tag = info.type;
        this.userDepartmentName = info.departmentName;
        this.userName = info.name;
    }
}

export default MapMarkerInfoData;