import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchCount() {
  return axios.get(baseUrl + baseApiVersion + `/dashboard/count`, { headers });
}

export function fetchTraffic() {
  return axios.get(baseUrl + baseApiVersion + `/dashboard/traffic`, { headers });
}

export function fetchEventList() {
  return axios.get(baseUrl + baseApiVersion + `/dashboard/event/list`, { headers });
}

export function CountEventList() {
  return axios.get(baseUrl + baseApiVersion + `/dashboard/event/addr/list`, { headers });
}

export function fetchEventCount(eventId) {
  return axios.get(baseUrl + baseApiVersion + `/dashboard/event/${eventId}/count`, {
    headers,
  });
}

export function fetchEventJoinList(type,params) {
  return axios.get(baseUrl + baseApiVersion + `/dashboard/join/${type}/list`, {
    headers,
    params,
  });
}

export function fetchEventLiveList(type,params) {
  return axios.get(baseUrl + baseApiVersion + `/dashboard/live/${type}/list`, {
    headers,
    params,
  });
}
