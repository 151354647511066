import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchSearchMap(params) {
  return axios.get(
    baseUrl +
      baseApiVersion + `/location/contents/contentsList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}` +
      `&registeredDateRange.from=${params.dateRange.from}&registeredDateRange.to=${params.dateRange.to}`,
    {
      headers,
    }
  );
}

export function fetchLocation(params) {
  return axios.get(baseUrl + baseApiVersion + `/location/list`,{
    params,
    headers,
  });
}